.infibg {
    background: #fff;
    padding: 30px 0px;

}

.info_number {
    /* color: #34B406; */
    font-size: 1.563rem;
    font-family: Poppins;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.25px;

}

.info_para {
    /* color: #1B1B1E; */
    font-size: 1.25rem;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.1px;
}

@media only screen and (max-width: 1440px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 991px) {




    .infibg {
        background: #fff;
        padding: 0px 0px 30px 0px;
    }

    .info_row>div:nth-child(3),
    .info_row>div:nth-child(4) {
        padding-top: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .info_number {
        padding-top: 20px;

    }


    .infibg {
        background: #fff;
        padding: 0px 0px 30px 0px;
    }

}

@media only screen and (max-width: 600px) {
    .info_number {
        font-size: 1.25rem;
    }

    .info_para {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 500px) {
    .info_number {
        padding-top: 0px;
    }

    .infibg {
        padding: 0px 0px;
    }

    .info_row {
        padding: 32px 8px;
    }

}

@media only screen and (max-width: 425px) {}