.box_main {
    /* padding: 72px 0px 0px 0px ; */
}

.box_main .box_img {
    padding-bottom: 24px;
}

.whyus_hadding {
    color: #1B1B1E;
    font-size: 3.07rem;
    font-family: Poppins;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.98px;
    /* padding-top: 72px; */

}

.box_main .box_headding {
    color: #1B1B1E;
    font-size: 1.50rem;
    font-family: Poppins;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.25px;
    padding-bottom: 12px;
}

.box_main .box_par {
    color: #737780;
    font-size: 1.25rem;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.1px;
}

.why_blockbg {
    /* background: #FCFCFC;   */
    /* margin-top: 40px; */
    padding: 72px 0px;
}

.row_sty {
    padding: 72px 0px;
}

@media only screen and (max-width:1400px) {}

@media only screen and (max-width:1200px) {}

@media only screen and (max-width:991px) {
    .row_sty {
        padding: 0px 0px;
    }

    .why_blockbg {
        padding: 60px 0px;
    }



    .box_main .box_img {
        padding-bottom: 12px;

    }

    .box_main .box_headding {
        padding-bottom: 8px;
    }

    .mob_sty {
        padding-top: 36px;
    }

    .req_main {
        padding-top: px;
    }

    .how_works .box_img img {
        width: 100%;
    }

    .whyus_hadding {
        font-size: 2.45rem;
    }



}

@media only screen and (max-width:768px) {

    .box_main {
        padding: 36px 0px 0px 0px;
    }


    .row_sty {
        padding: 0px 0px;
    }



    .why_blockbg {
        padding: 40px 0px;
    }
}

@media only screen and (max-width:600px) {
    .whyus_hadding {
        font-size: 2rem;
    }

    .box_main .box_headding {
        font-size: 1.25rem;
    }

    .box_main .box_par {
        font-size: 1rem;
    }

    .box_main .box_img {}

    .mob_box_img {
        max-width: 100%;
        width: 28px;
    }
}

@media only screen and (max-width:500px) {


    .why_blockbg {
        padding: 36px 8px;
    }
}

@media only screen and (max-width:375px) {}