.check_block {
    padding: 72px 0px;
}

.li_style {
    color: #1B1B1E;
    font-size: 1.25rem;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.1px;
    padding-top: 16px;
}

.ul_style {
    padding-left: 1rem;
}

.check_headding {
    color: #1B1B1E;
    font-size: 3.07rem;
    font-family: Poppins;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.98px;
}

.check_row {
    padding: 72px 0px;
}

@media only screen and (max-width:1600px) {}

@media only screen and (max-width:1400px) {}

@media only screen and (max-width:1200px) {}

@media only screen and (max-width:991px) {
    .check_row {
        padding: 20px 0px 0px 0px;
    }

    .check_block {
        padding: 60px 0px;
    }

}

@media only screen and (max-width:768px) {

    .check_block {
        padding: 40px 0px;
    }
}

@media only screen and (max-width:600px) {
    .check_headding {
        font-size: 2rem;
    }

    .li_style {
        font-size: 1rem;
    }
}

@media only screen and (max-width:500px) {

    .check_block {
        padding: 36px 8px;
    }
}

@media only screen and (max-width:375px) {}