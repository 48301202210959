body {
    font-family: 'Poppins';
    font-style: normal;
}

.App {
    text-align: center;
}

img {
    max-width: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

a {
    text-decoration: none !important;
}

p {
    margin-bottom: 0px !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0px !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.error_color {
    width: 100%;
    list-style-type: none;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    font-weight: 500;
    line-height: 17px;
    color: #ff2b2b;
    border-top: none;
    border-radius: 0 0 4px 4px;
    padding: 0px 10px;
    text-align: start;
    transition: all 0.2s ease;
    margin-bottom: 5px;
    padding: 5px 0px 20px 0px;
}


/* .pageWidth{
  max-width: 1440px;
  margin: auto;
} */

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* common css */

.req_btn {
    border: none;
    border-radius: 18px;
    background: #34B406;
    padding: 14px 32px;
    color: #FCFCFC;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.1px;
    text-decoration-line: underline;
}

@media only screen and (max-width:991px) {
    .req_main {
        padding-top: 36px;
    }
}

@media only screen and (max-width:768px) {
    .req_btn {
        font-size: 16px;
    }
    .innercontainer {
        margin: 0px 0px;
    }
}

@media only screen and (max-width:600px) {
    .req_btn {
        padding: 12px 26px;
    }
}

#spinner {
    display: flex;
    justify-content: center;
    padding-top: 150px;
}

.dots {
    position: relative;
    width: 150px;
    height: 50px;
    transform: scale(0.25, 0.25);
}

.dot {
    width: 53px;
    height: 53px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
    border-radius: 50%;
    background-color: #EB275F;
}

.dot-1 {
    animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
    animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
    animation: animation-dots 1.5s infinite linear;
}

@-moz-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@-webkit-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@-o-keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}

@keyframes animation-dots {
    0% {
        left: -150px;
        opacity: 0;
    }
    20% {
        left: 0px;
        opacity: 1;
    }
    80% {
        left: 100px;
        opacity: 1;
    }
    100% {
        opacity: 0;
        left: 200px;
    }
}