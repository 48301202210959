.faq_block {
    padding: 72px 0px;
}

.faq_inner {
    padding: 72px 0px 36px 0px;
}

.faq_headding {
    color: #1B1B1E;
    font-size: 3.07rem;
    font-family: Poppins;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -0.98px;
}

.faq_qus {
    /* color: #1B1B1E; */
    font-size: 20px;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.1px;
}

.faq_qus {
    color: #1B1B1E !important;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.1px;
}

.view_btn {
    color: #1B1B1E;
    font-size: 16px;
    font-family: Poppins;
    line-height: 150%;
    border-radius: 13px;
    padding: 10px 15px;
    border: none;
    border-radius: 13px;
    background: transparent;
    border: 1px solid #D8DFF0;

}

.view_btn img {
    margin-left: 5px;
}

.qus_txt {
    color: #1B1B1E;
    font-size: 20px;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.1px;
}

.ans_txt {
    color: #1B1B1E;
    font-size: 1rem;
    font-family: Poppins;
    line-height: 150%;
    letter-spacing: -0.1px;
}

/* accordion css */
.accordion-item {
    border: none;
    border-bottom: 1px solid #D8DFF0;
    border-radius: 0px !important;
    margin: 5px 0px;
}

.accordion-button:not(.collapsed) {
    background: transparent !important;
    box-shadow: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button {
    /* border-radius: 8px; */
    padding: 16px;
}

.faq_inner .accordion-body {
    padding: 5px 20px;
}

.faq_block .accordion-button::after {
    background-image: url(/public/static/assets/images/faq_arrow2.svg);
    transform: rotate(-90deg);
}

.FaqBlockContainer .accordion-button::after {
    background-image: url(/public/static/assets/images/faq_arrow2.svg);
    transform: rotate(-90deg);
}

.accordion-button:not(.collapsed)::after {
    background-image: url(/public/static/assets/images/faq_arrow1.svg);
    transform: rotate(-180deg);
}


@media only screen and (max-width:1200px) {}


@media only screen and (max-width:991px) {
    .faq_block {
        padding: 60px 0px;
    }

    .faq_inner {
        padding: 36px 0px 36px 0px;
    }

    .check_headding {
        font-size: 2.45rem;
    }

}

@media only screen and (max-width:768px) {


    .faq_block {
        padding: 40px 0px;
    }

    .accordion-button {
        padding: 16px 0px;
    }
}

@media only screen and (max-width:600px) {
    .faq_headding {
        font-size: 2rem;
    }

    .faq_qus {
        font-size: 1rem;
    }

    .view_btn {
        font-size: 0.9rem;
    }
}

@media only screen and (max-width:500px) {

    .faq_block {
        padding: 36px 8px;
    }
}

@media only screen and (max-width:375px) {


    .faq_qus {
        font-size: 16px;
    }

    .ans_txt {
        font-size: 0.9rem;
    }
}