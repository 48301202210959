.bannerForm {
    background: #FFFFFF;
    box-shadow: 0px 6px 32px rgba(16, 38, 8, 0.32);
    border-radius: 6px;
    /* margin: 20px; */
    padding: 48px 48px;
}

.from_headding {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.563rem;
    line-height: 150%;
    /* identical to box height, or 38px */
    letter-spacing: -0.02em;
    color: #1B1B1E;
}

.form_field {
    display: grid;
}

.form_field label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    color: #1B1B1E;
    padding-top: 25px;
    padding-bottom: 8px;
}

.form_field input {
    padding: 13px 16px 13px 48px;
    background: #F5F5F5;
    border-radius: 16px;
    border: none;
    font-size: 1.25rem;
    font-family: 'Poppins';
    font-weight: 500;
}

.form_field input:focus {
    outline: none;
}

.bannerForm .requst_btn {
    width: 100%;
    padding: 9px 20px;
    border-radius: 18px;
    margin-top: 12px;
    border: none;

    color: #FCFCFC;
    text-align: center;
    font-family: Poppins;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
}

/* .form_field input:focus-within {
    border: 1px solid #34B406;
}

.form_field input:hover {
    border: 1px solid red;
    margin: -1px;
} */

.banner_para {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #737780;
    padding-top: 12px;
}

.banner_para span {
    cursor: pointer;
}

.bottm_par_main {
    display: flex;
    align-items: center;
    padding-top: 12px;
}

.banner_bottompara {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    color: #1B1B1E;
}

.span_dot {
    width: 8px;
    height: 8px;
    background: #34B406;
    border-radius: 50%;
    margin-right: 8px;
}

.input_main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.input_main img {
    position: absolute;
    left: 16px;
}

.innercontainer {
    padding-bottom: 80px;
}

/* Place holder colo */
::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #D5DAE5;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #BABEC8;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #BABEC8;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #BABEC8;
}

/* auto fill */
/* Change the white to any color */
/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
} */

@media only screen and (max-width: 1800px) {}

@media only screen and (max-width: 1600px) {}


@media only screen and (max-width: 1440px) {}



@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 991px) {
    .bannerForm {
        margin: 0px;
        padding: 32px 48px;
    }

    .form_field input {
        width: 100%;
    }



    .form_field label {

        padding-top: 18px;
    }



    .innercontainer {
        padding-bottom: 0px;
    }



}

@media only screen and (max-width: 768px) {
    .bannerForm {

        padding: 30px 20px;
    }

}

@media only screen and (max-width: 600px) {
    .form_field label {
        font-size: 0.82rem;
    }

    .form_field input {
        font-size: 1rem;
    }

    .banner_para {
        font-size: 10px;
    }

    .bannerForm .requst_btn {
        font-size: 1rem;
    }

    .banner_bottompara {
        font-size: 0.82rem;
    }

    .bannerForm .requst_btn {
        padding: 12px 20px;

    }

    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #D5DAE5;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }

    .banner_para {
        font-size: 12px;
        /* padding-top: 5px; */
    }



}

@media only screen and (max-width: 500px) {

    .bannerForm {
        padding: 32px 20px;
        margin: 0px -24px;
        margin-bottom: -22px;
        box-shadow: none;
        border-radius: 0px;
    }

    .banner_para {
        padding-top: 24px;
    }

    .bottm_par_main {
        justify-content: center;
    }


}


@media only screen and (max-width: 425px) {



    .bannerForm .requst_btn {
        padding: 12px 20px;
        font-size: 16px;
    }

}

@media only screen and (max-width: 375px) {


    .bannerForm {
        padding: 32px 20px;

    }

    .form_field label {
        padding-top: 16px;
    }


    .from_headding {
        font-size: 20px;
    }
}